@tailwind base;
@tailwind components;
@tailwind utilities;

.form-style {
  @apply py-3 md:py-2 rounded-lg  border-gray-200 text-sm focus:border-primary focus:ring-primary;
}

@font-face {
  font-family: "helvetica";
  src: url("/public/helvetica/Helvetica.ttf");
}
@font-face {
  font-family: "helvetica";
  font-weight: 500;
  src: url("/public/helvetica/Helvetica-Bold.ttf");
}
